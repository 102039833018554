import { BadRequest } from '@tsed/exceptions'
import querySiteOptions from 'query/siteOptions'
import queryHomepage from '../../../query/homepage'
import { homePageConfig } from '../home.config'
import HomePage from '../pages/HomePage'
import type { THomePage } from '../pages/HomePage'

export default function HomeRoute(props: THomePage) {
  return <HomePage {...props} />
}

export const getHomeStaticProps = async (locale?: string) => {
  if (locale === undefined) {
    throw new BadRequest('locale is missing')
  }

  const pageProps = await queryHomepage('/', locale)
  const siteOptionsProps = await querySiteOptions(locale)

  const { i18nNamespaces } = homePageConfig
  if (pageProps.page == null) {
    return {
      notFound: true,
      i18nNamespaces,
      locale,
    }
  }

  return { i18nNamespaces, locale, props: { ...siteOptionsProps, ...pageProps } }
}
