import React from 'react'
import { styled } from 'twin.macro'
import Heading from '../Heading/index'
import type { THomepageHeroProps } from './interface'
import baseStyle from './styles'

const StyledHomepageHero = styled.div(() => [baseStyle])

const HomepageHero = (props: THomepageHeroProps) => {
  const { title, children } = props

  return (
    <StyledHomepageHero {...props}>
      <div tw="pb-12 mt-36 px-6 md:px-8 lg:pt-16 lg:mt-0 lg:px-10 2xl:px-14 lg:self-center">
        <Heading variant="h1" as="h1" tw="text-tertiary-5 font-semibold uppercase">
          {title}
        </Heading>
      </div>
      <div tw="relative">{children}</div>
    </StyledHomepageHero>
  )
}

export default HomepageHero
