import type { I18nActiveNamespaces } from '@/lib/i18n'

export type HomeConfig = {
  // Define namespaces in use in both the type and the config.
  i18nNamespaces: I18nActiveNamespaces<'common'>
}
export const homePageConfig: HomeConfig = {
  /** Namespaces that should be loaded for this page */
  i18nNamespaces: ['common'],
}
