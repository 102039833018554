import { graphql } from '../../gql'

export const lastPosts = graphql(/* GraphQL */ `
  query lastPosts($first: Int) {
    posts(first: $first) {
      nodes {
        title
        link
        uri
        date
        slug
        categories {
          nodes {
            name
          }
        }
      }
    }
  }
`)
