import request from 'graphql-request'
import { PageIdType } from 'gql/graphql'
import { lastPosts } from 'query/last-posts'
import { QUERY_LATEST_NEWS_COUNT, QUERY_TEAM_NUMBER_COUNT } from '../../../const'
import getWpUrlFromLang from '../../backend/wp'
import { graphql } from '../../gql'

export const homepage = graphql(/* GraphQL */ `
  query homepage($id: ID! = "accueil", $idType: PageIdType = URI, $asPreview: Boolean = false, $first: Int) {
    page(id: $id, idType: $idType, asPreview: $asPreview) {
      title
      flexibleContent {
        okamContent {
          ...PageFlexibleContentFragment
        }
      }
      seo {
        ...SeoData
      }
      translatedUri
    }
    members(first: $first) {
      ...AllMembers
    }
    mainMenu: menuItems(where: { location: PRIMARY_MENU, parentId: 0 }) {
      ...MainMenu
    }
  }
`)

async function queryHomepage(id: string, language?: string) {
  const wpUrl = getWpUrlFromLang(language)
  const idType = PageIdType.Uri
  const dataPage = await request(wpUrl, homepage, {
    id,
    idType,
    asPreview: false,
    first: QUERY_TEAM_NUMBER_COUNT,
    language,
  })

  const dataLastPosts = await request(wpUrl, lastPosts, {
    first: QUERY_LATEST_NEWS_COUNT,
  })

  return { ...dataPage, ...dataLastPosts }
}

export default queryHomepage
