import { Inline } from '@bedrock-layout/primitives'
import { BackgroundVideo, HomepageHero } from '@okam/brand-ui/index'
import { useTranslation } from 'next-i18next'
import { useFragment } from 'gql'
import type { HomepageQuery, LastPostsQuery, Maybe, SiteOptionsQuery, PostTypeSeo, MainMenuFragment } from 'gql/graphql'
import { allMembersFragment } from 'query/fragments'
import FlexibleContentDispatcher from '../../../components/flexible-content/FlexibleContentDispatcher/FlexibleContentDispatcher'
import NewsLayout from '../../../components/flexible-content/NewsLayout/NewsLayout'
import type { TFlexibleContent, TNewsLayout } from '../../../components/flexible-content/types'
import { MainLayout } from '../../../components/layout'

export interface THomePage {
  pageProps: HomepageQuery & LastPostsQuery & SiteOptionsQuery & PostTypeSeo
}
const HomePage = ({ pageProps }: THomePage) => {
  const { page, siteOptions, mainMenu } = pageProps
  const okamContent = pageProps.page?.flexibleContent?.okamContent as Array<TFlexibleContent>
  const allMembers = useFragment(allMembersFragment, pageProps.members)
  const { t } = useTranslation('common')

  return (
    <MainLayout
      siteOptionsProps={siteOptions}
      seo={page?.seo}
      allMembers={allMembers}
      menuItems={mainMenu as MainMenuFragment}
      variant="half"
      translatedUri={page?.translatedUri}
    >
      <Inline tw="mx-auto max-w-[1920px] block w-full" justify="center">
        <HomepageHero title={page?.title ?? ''}>
          <BackgroundVideo url={t('HOME.VIDEO')} aspectRatio="1/1" />
        </HomepageHero>
      </Inline>
      {okamContent.map((content: TFlexibleContent, index: number) => {
        /* eslint-disable-next-line */
        const type = content?.__typename
        if (
          type === 'Expertise_Flexiblecontent_OkamContent_NewsLayout' ||
          type === 'Page_Flexiblecontent_OkamContent_NewsLayout'
        ) {
          const props = { ...pageProps, ...content } as TNewsLayout & Maybe<LastPostsQuery>
          /* eslint-disable-next-line react/no-array-index-key */
          return <NewsLayout key={`page-flexibleContent-${content?.fieldGroupName}-${index}`} {...props} />
        }
        return (
          /* eslint-disable react/no-array-index-key */
          <FlexibleContentDispatcher
            allMembers={allMembers}
            content={content}
            key={`page-flexibleContent-${content?.fieldGroupName}-${index}`}
          />
        )
      })}
    </MainLayout>
  )
}

export default HomePage
