import { Player } from '@lottiefiles/react-lottie-player'
import tw, { css } from 'twin.macro'
import type { TBackgroundVideoProps } from './interface'

const BackgroundVideo = (props: TBackgroundVideoProps) => {
  const { url, aspectRatio } = props

  return (
    <div
      css={[
        aspectRatio &&
          css`
            aspect-ratio: ${aspectRatio};
          `,
        tw`h-auto w-auto`,
      ]}
    >
      <Player src={url} autoplay loop style={{ inset: '0', position: 'absolute' }} />
    </div>
  )
}

export default BackgroundVideo
